import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './main.scss';
import React, {Component}  from "react";
import {graphql, StaticQuery} from 'gatsby';
import Person from "../components/person";
import MenuContainer from "../components/menu-container";
import getFrontmatter from '../helpers/getFrontmatter';
import {MDBCard, MDBCardBody, MDBCardText, MDBCardTitle} from "mdbreact";

export default class WieZijnWij extends Component {
    
   render() {
       return (
       <StaticQuery
           query={graphql`
              query {
                allMarkdownRemark(filter: {fields: {slug: {eq: "/onze-mensen/"}}}) {
                  edges {
                    node {
                      frontmatter {
                        pagina_titel,
                        pagina_omschrijving,
                        onze_mensen {
                          naam,
                          functie,
                          omschrijving,
                          foto {
                            childImageSharp {
                              gatsbyImageData(width: 500)
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
           render={data => {
              const frontmatter = getFrontmatter(data);

              const persons = frontmatter.onze_mensen.map((person, index) => {
                  return <Person key={index} title={person.naam} picture={person.foto} description={person.omschrijving} job={person.functie}/>;
              });

               return (
                   <div className="who-are-we">
                       <MenuContainer/>
                       <div className="container">
                           <div className="row justify-content-end">
                               <div className="col-12 col-sm-8">
                                   <MDBCard className="mt-5">
                                       <MDBCardBody className="border-bottom border-primary">
                                           <MDBCardTitle className="tenby-five">{frontmatter.pagina_titel}</MDBCardTitle>
                                          <MDBCardText>{frontmatter.pagina_omschrijving}</MDBCardText>
                                       </MDBCardBody>
                                   </MDBCard>
                               </div>
                           </div>
                       </div>
                       <div className="container-fluid">
                           <div className="row justify-content-center mb-5 pb-5">
                               {persons}
                           </div>
                       </div>
                   </div>
               )
           }}
       />
       );
   }
}
