import React, {Component}  from "react";
import {MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle} from "mdbreact";
import { getSrc  } from "gatsby-plugin-image"


class Brand extends Component {

    render() {
        const {logo, merk_naam, merk_tekst} = this.props;
        const image = getSrc(logo.childImageSharp.gatsbyImageData)

        return (
            <div className="row">
                <div className="col">
                    <MDBCard className="mt-5">
                        <MDBCardImage className="p-4 brand-logo" src={image} waves />
                        <MDBCardBody className="border-bottom border-primary">
                            <MDBCardTitle className="tenby-five">{merk_naam}</MDBCardTitle>
                            <MDBCardText>{merk_tekst}</MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </div>
            </div>
        );
    }
}

export default Brand;