import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './main.scss';
import React, {Component}  from "react";
import {graphql, StaticQuery} from 'gatsby';
import Brand from "../components/brand";
import getFrontmatter from '../helpers/getFrontmatter';
import MenuContainer from "../components/menu-container";

import {MDBCard, MDBCardBody, MDBCardText, MDBCardTitle} from "mdbreact";

export default class OnzeMerken extends Component {

    buildBrand(brand, index) {
        return <Brand index={index} merk_naam={brand.merk_naam} merk_tekst={brand.merk_tekst} logo={brand.logo} />;
    }
    
   render() {
       return (
       <StaticQuery
           query={graphql`
              query {
                 allMarkdownRemark(filter: {fields: {slug: {eq: "/onze-merken/"}}}) {
                    edges {
                      node {
                        frontmatter {
                          pagina_titel,
                          pagina_omschrijving,
                          onze_merken {
                            merk_naam,
                            merk_tekst,
                            logo {
                              childImageSharp {
                                gatsbyImageData(width: 500)
                              }
                            }
                          }
                        }
                      }
                    }
                 }
              }
            `}
           render={data => {
               const frontmatter = getFrontmatter(data);
               const {pagina_titel, pagina_omschrijving} = frontmatter;
               
               const odd = frontmatter.onze_merken.filter((dirk, index) => !(index % 2)).map(this.buildBrand);
               const even = frontmatter.onze_merken.filter((dirk, index) => index % 2).map(this.buildBrand);

               return (
                   <div className="our-brands">
                       <MenuContainer />
                       <div className="container">
                           <div className="row justify-content-end">
                               <div className="col-12 col-sm-8">
                                   <MDBCard className="mt-5">
                                       <MDBCardBody className="border-bottom border-primary">
                                           <MDBCardTitle className="tenby-five">{pagina_titel}</MDBCardTitle>
                                           <MDBCardText>{pagina_omschrijving}</MDBCardText>
                                       </MDBCardBody>
                                   </MDBCard>
                               </div>
                           </div>
                           <div className="row">
                               <div className="col-12 col-md-6">
                                   {odd}
                               </div>
                               <div className="col-12 col-md-6">
                                   {even}
                               </div>
                           </div>
                       </div>
                   </div>
               )
           }}
       />
       );
   }
}
